<template>
  <div class="d-flex align-center">
    <div
      class="d-flex align-center"
      v-if="computedItems.length > 0"
    >
      <span
        v-for="(v, i) in computedItems"
        :key="'assigned' + i"
        class="mr-2 align-center"
      >
        <computed-document-ref
          :id="v.id"
          :document-type="v.type"
          :options="{hideNavigation:true, isForSelection: false, hideEmail: false}"
        />
      </span>
      <v-chip v-if="sizeItems">{{sizeItems}}</v-chip>
    </div>
    <v-icon v-else>{{$icon('i.None')}}</v-icon>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref')
  },
  computed: {
    computedItems () {
      return this.value?.set.slice(0, 3) ?? []
    },
    sizeItems () {
      return this.value?.set?.length <= 3 ? '' : '+' + (this.value?.set?.length - 3).toString()
    }
  },
  props: {
    value: Object
  }
}
</script>
